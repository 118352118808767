exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-before-and-after-permanent-eyebrows-js": () => import("./../../../src/pages/before-and-after-permanent-eyebrows.js" /* webpackChunkName: "component---src-pages-before-and-after-permanent-eyebrows-js" */),
  "component---src-pages-before-and-after-permanent-eyeliner-js": () => import("./../../../src/pages/before-and-after-permanent-eyeliner.js" /* webpackChunkName: "component---src-pages-before-and-after-permanent-eyeliner-js" */),
  "component---src-pages-before-and-after-permanent-lips-js": () => import("./../../../src/pages/before-and-after-permanent-lips.js" /* webpackChunkName: "component---src-pages-before-and-after-permanent-lips-js" */),
  "component---src-pages-before-and-after-permanent-makeup-js": () => import("./../../../src/pages/before-and-after-permanent-makeup.js" /* webpackChunkName: "component---src-pages-before-and-after-permanent-makeup-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-success-js": () => import("./../../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/BlogPost.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */)
}

