/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import React from 'react'
import PropTypes from 'prop-types'
import { PageProvider } from './src/context/PageContext'

export const wrapRootElement = ({ element }) => (
  <PageProvider>{element}</PageProvider>
)

// export default class wrapRootElement extends React.Component {
//   render() {
//     const { element } = this.props
//     return <PageProvider>{element}</PageProvider>
//   }
// }

wrapRootElement.propTypes = {
  element: PropTypes.element.isRequired,
}
