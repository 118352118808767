import React, { createContext, useState, useEffect } from 'react'

const defaultValues = {
  isNavOpen: false,
}

export const PageContext = createContext(defaultValues)

// Check if it's a browser
// const isBrowser = typeof window !== 'undefined'

export const PageProvider = ({ children }) => {
  const [isNavOpen, setNavOpen] = useState(false)
  // debugger

  const toggleNavOpen = () => setNavOpen(!isNavOpen)

  useEffect(() => {
    // initializeCheckout()
  }, [])

  return (
    <PageContext.Provider
      value={{ ...defaultValues, isNavOpen, toggleNavOpen }}
    >
      {children}
    </PageContext.Provider>
  )
}
